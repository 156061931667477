.ant-layout, .ant-layout-footer, .ant-layout-header, .ant-page-header {
    background-color: white !important;
}

.claims-page-header {
    border: 1px solid rgb(235, 237, 240);
  }

.claims-header {
    padding: 0 !important;
}

.claims-content > div {
    margin-top: 0;
}

.no-margin-notes {
    color: #a8a8a8;
    font-size: small;
    margin-bottom: 0;
    margin-top: 0;
}